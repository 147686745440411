// src/pages/memoires/index.js
import React from 'react';
import Layout from '../../component/Layout/Layout';

export function Home() {
  return (
    <Layout>
      <h1>Home Page</h1>
      <p>This is the Home page</p>
    </Layout>
  );
}

export default Home;
