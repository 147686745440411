// src/pages/theses/index.js
import React from 'react';
import Layout from '../../component/Layout/Layout';

export function ThesesPage() {
  return (
    <Layout>
      <h1>Theses Page</h1>
      <p>This is the Theses page. Here you will find information about various academic theses.</p>
    </Layout>
  );
}

export default ThesesPage;
