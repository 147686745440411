// src/components/Layout/PortailLayout.js
import React, { useEffect, useState } from 'react';
import { AppShell, Group } from '@mantine/core';
import styled from 'styled-components';
import PortailNavbar from '../navbar/PortailNavbar';
import FooterLinks from '../FooterLinks';

// Styled Components
const MainContentSection = styled.main`
  min-height: 100vh; /* Cover the entire viewport height */
  background: white; /* Solid background to hide the footer */
  position: relative;
  z-index: 1;
  font: 16px/1.4 system-ui, sans-serif;

  /* Adding shadow and relief at the bottom */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #252a47;
  place-items: center;

  /* Ensure footer is below the main content until scrolled */
  z-index: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  /* Apply sliding effect when body has the 'is-at-bottom' class */
  ${({ isAtBottom }) => isAtBottom && `
    transform: translateY(0%);
  `}
`;

const PortailLayout = ({ children }) => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setIsAtBottom(isAtBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppShell
      header={{ height: 60 }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" w="100%" position="apart">
          <PortailNavbar />
        </Group>
      </AppShell.Header>

      <MainContentSection>
        {children}
      </MainContentSection>

      <Footer isAtBottom={isAtBottom}>
        <FooterLinks />
      </Footer>
    </AppShell>
  );
};

export default PortailLayout;
