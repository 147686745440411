import React from 'react';
import MyNavbar from '../../component/navbar/Navbar';
import { AppShell,Group,Burger,Loader } from '@mantine/core';
import {NavbarNested} from "../../component/verticalNavbar/NavbarNested"
import { useDisclosure } from '@mantine/hooks';
import "./chatbot.css"




export const  ExternalApp = () => {
  const [opened, { toggle }] = useDisclosure();
  return (
    <div>
      <AppShell
              header={{ height: 60 }}
              navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
              padding="md"
            >
              <AppShell.Header>
              <Group h="100%" w="100%">
                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                <MyNavbar />
                </Group>
              </AppShell.Header>
              
              <AppShell.Navbar p="md" style={{"backgroundColor":"#F5F5F5"}} >
              <NavbarNested />
              </AppShell.Navbar>
        
              <AppShell.Main>
              <div style={{ width: '100%', height: '90vh'}}>
              <iframe
                    title="External App"
                    src="https://langchain-chainlit-chat-app-6ow4e7zrpq-uc.a.run.app/"
                    width="100%"
                    height="100%"
                   
            
                > <Group  mt="md" mb="xs" justify="center">
                <Loader color="blue" size="xl" type="dots" />
                      </Group> 
                </iframe>                
              </div>
              </AppShell.Main>
            </AppShell>

    </div>
  );
};

