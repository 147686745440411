import React from 'react';
import styled from 'styled-components';
import { IconBook, IconGavel, IconScale, IconFileText, IconFileCertificate, IconArticle } from '@tabler/icons-react';
import { Container } from '@mantine/core';
import { colors, fonts } from './themes'; // Import colors and fonts from the theme

const LegalInfoWrapper = styled.div`
  padding: 2rem 1rem; /* Reduced padding for mobile */
  background-color: ${colors.white};
  text-align: center;
`;

const LegalInfoContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem; /* Add some padding on mobile for spacing */
  }
`;

const Title = styled.h3`
  text-align: center;
  color: ${colors.text};
  font-family: ${fonts.heading}; 
  font-size: 1.75rem; /* Slightly reduced size */
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Further reduced for mobile */
  }
`;

const LegalInfoItem = styled.div`
  flex: 1 1 30%;
  max-width: 300px;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: ${colors.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const LegalInfoIcon = styled.div`
  margin-bottom: 1rem;
  color: ${colors.blue.dark};
  font-size: 2rem;
`;

const LegalInfoText = styled.p`
  color: ${colors.text};
  font-family: ${fonts.body}; 
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const LegalInfoDescription = styled.p`
  color: #4a5568;
  font-family: ${fonts.body}; 
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const UpdateMessage = styled.p`
  color: #718096;
  font-size: 0.75rem;
  margin-top: 2rem;
  text-align: center;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 0.65rem; /* Smaller font for mobile */
  }
`;

const PointedHr = styled.hr`
  border: none;
  height: 2px;
  background: linear-gradient(to right, ${colors.blue.dark}, ${colors.blue.superDark});
  background-color: ${colors.background};
  position: relative;
  margin: 2rem auto;
  width: 80%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -7px;
    border-style: solid;
    border-width: 0 10px 10px 0;
  }

  &::before {
    left: 0;
    border-color: transparent ${colors.blue.dark} ${colors.blue.dark} transparent;
  }

  &::after {
    right: 0;
    transform: rotate(45deg);
    border-color: ${colors.blue.superDark} transparent transparent ${colors.blue.superDark};
  }
`;

export function LegalInformationList() {
  return (
    <LegalInfoWrapper>
      <PointedHr />
      <Title>Informations Juridiques Disponibles</Title>
      <LegalInfoContainer>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconBook size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Codes et lois sénégalais</LegalInfoText>
          <LegalInfoDescription>
            Accédez à une base complète des codes et lois en vigueur au Sénégal.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconGavel size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Jurisprudence nationale</LegalInfoText>
          <LegalInfoDescription>
            Consultez les décisions rendues par les tribunaux et les cours de justice au Sénégal.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconScale size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Droit de l'OHADA et Actes uniformes</LegalInfoText>
          <LegalInfoDescription>
            Retrouvez les actes uniformes et les textes du droit OHADA appliqués dans toute la zone.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconGavel size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Justice communautaire CEDEAO</LegalInfoText>
          <LegalInfoDescription>
            Accédez aux décisions de la Cour de justice de la CEDEAO.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconGavel size={40} />
          </LegalInfoIcon>
          <LegalInfoText>CCJA (OHADA)</LegalInfoText>
          <LegalInfoDescription>
            Retrouvez les décisions de la Cour Commune de Justice et d'Arbitrage de l'OHADA.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconFileText size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Conventions fiscales</LegalInfoText>
          <LegalInfoDescription>
            Explorez les conventions fiscales en vigueur et leur impact sur les entreprises et les particuliers.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconFileText size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Conventions collectives</LegalInfoText>
          <LegalInfoDescription>
            Consultez les conventions collectives qui régissent les relations de travail au Sénégal.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconArticle size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Doctrine</LegalInfoText>
          <LegalInfoDescription>
            Accédez aux ouvrages, articles, et mélanges doctrinaux disponibles pour approfondir vos connaissances juridiques.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconFileCertificate size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Thèses</LegalInfoText>
          <LegalInfoDescription>
            Consultez les thèses disponibles en droit pour enrichir vos recherches académiques.
          </LegalInfoDescription>
        </LegalInfoItem>
        <LegalInfoItem>
          <LegalInfoIcon>
            <IconFileCertificate size={40} />
          </LegalInfoIcon>
          <LegalInfoText>Mémoires</LegalInfoText>
          <LegalInfoDescription>
            Retrouvez les mémoires rédigés dans le domaine du droit pour approfondir votre compréhension.
          </LegalInfoDescription>
        </LegalInfoItem>
      </LegalInfoContainer>
      <UpdateMessage>
        Ces informations sont enrichies tous les jours. Des textes seront disponibles au fur et à mesure.
      </UpdateMessage>
      <PointedHr />
    </LegalInfoWrapper>
  );
}

export default LegalInformationList;
