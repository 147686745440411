// src/pages/AboutUsPage.jsx

import React from 'react';
import PortailLayout from '../component/Layout/PortailLayout'; // Ajuste le chemin si nécessaire
import AboutPage from '../component/AboutPage'; // Assurez-vous d'avoir bien créé AboutPage

export function AboutUsPage() {
  return (
    <PortailLayout>
      <AboutPage />
    </PortailLayout>
  );
}

export default AboutUsPage;
