// src/pages/AboutPage.jsx
import React from 'react';
import styled from 'styled-components';
import { fonts, colors } from './themes';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
  background-color: ${colors.background};
  color: ${colors.text};
  font-family: ${fonts.body};
`;

const Slogan = styled.h2`
  font-family: ${fonts.heading};
  font-size: 2.5rem;
  color: ${colors.primary};
  margin-bottom: 1rem;
  text-align: center;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.accent};
  }
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.lindworm};
  margin-bottom: 2rem;
  text-align: center;
`;

const Content = styled.div`
  max-width: 800px;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
  margin: 0 auto;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AboutPage = () => {
  return (
    <div>
      <hr></hr>
    <AboutContainer>
    <Title>À propos de notre plateforme</Title>
      <Slogan>« Accès simplifié au droit sénégalais et africain »</Slogan>
      <Content>
        <Paragraph>
          Notre mission est de rendre le droit africain, et en particulier le droit sénégalais, accessible et compréhensible pour tous. Nous croyons fermement que la transparence et l'accès à l'information juridique sont essentiels pour une société juste et équitable.
        </Paragraph>
        <Paragraph>
          En centralisant les données juridiques et en les structurant avec précision, nous facilitons l'accès aux lois, décisions de justice, et réglementations. Grâce à l'intelligence artificielle, notre plateforme permet aux professionnels du droit et au grand public de trouver rapidement des informations fiables et à jour.
        </Paragraph>
        <Paragraph>
          Rejoignez-nous pour explorer une ressource juridique complète et innovante qui répond aux besoins spécifiques de la communauté africaine et accompagne son développement. Ensemble, nous faisons progresser la justice et la compréhension du droit en Afrique.
        </Paragraph>
      </Content>
    </AboutContainer>
    </div>

  );
};

export default AboutPage;
