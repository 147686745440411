// src/pages/LegalNoticePage.jsx
import React from 'react';
import styled from 'styled-components';
import PortailLayout from '../component/Layout/PortailLayout';
import { fonts, colors } from '../component/themes';

const LegalNoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1rem;
  background-color: ${colors.white};
  color: ${colors.text};
  font-family: ${fonts.body};
  margin-top: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.lindworm};
  margin-bottom: 1.5rem;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const Subtitle = styled.h4`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${colors.primary};
  margin-top: 1rem;
  text-align: left;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
`;

const LegalNoticePage = () => {
  return (
    <PortailLayout>
      <LegalNoticeContainer>
        <Title>Mentions Légales</Title>

        <Section>
          <Subtitle>Éditeur du site</Subtitle>
          <Paragraph>
            Le site <strong>legi.sn</strong> est exploité par Ndongo Benoit Ndiaye, immatriculé en France.
            Pour toute demande d'information, vous pouvez nous contacter par email à <strong>n.ben.ndiaye@gmail.com</strong>.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Hébergeur</Subtitle>
          <Paragraph>
            Le site est hébergé par <strong>OVH</strong>. Vous pouvez les joindre au siège social situé au 2 rue Kellermann, 59100 Roubaix, France,
            ou par téléphone au <strong>+33 9 72 10 10 07</strong>.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Source des données</Subtitle>
          <Paragraph>
            Nos données proviennent de sources variées et fiables, incluant :
          </Paragraph>
          <ul style={{ textAlign: 'left' }}>
            <li>Le <strong>Journal Officiel</strong> pour les publications officielles de textes législatifs.</li>
            <li>Les bases de données de la <strong>Cours de Cassation</strong> et autres juridictions supérieures.</li>
            <li>Les institutions régionales telles que la <strong>CEDEAO</strong> et l’<strong>OHADA</strong>.</li>
            <li>La base de données <strong>Juricaf</strong> pour la jurisprudence francophone.</li>
            <li>Nos <strong>partenariats institutionnels</strong> avec diverses organisations juridiques.</li>
            <li>Nos <strong>sources propriétaires</strong>, enrichies et vérifiées en interne.</li>
          </ul>
        </Section>

        <Section>
          <Subtitle>Engagement pour l'inclusivité et l'égalité</Subtitle>
          <Paragraph>
            En accord avec notre engagement pour l'égalité, nous mettons tout en œuvre pour offrir un environnement inclusif et équitable. Nous valorisons 
            l'inclusivité et travaillons constamment à améliorer nos pratiques pour mieux représenter la diversité de nos utilisateurs et partenaires.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Données personnelles</Subtitle>
          <Paragraph>
            Conformément à la loi n°2008-12, vous disposez de droits d'accès, de rectification, d'opposition et de suppression concernant vos données personnelles.
            Vous pouvez nous contacter à <strong>abuse@nic.sn</strong> pour toute demande à ce sujet.
          </Paragraph>
        </Section>

        <Section>
          <Subtitle>Informations légales supplémentaires</Subtitle>
          <Paragraph>
            Toutes les informations sur ce site sont fournies à titre indicatif et peuvent être sujettes à modification sans préavis. Nous faisons de notre mieux pour garantir leur exactitude mais nous déclinons toute responsabilité en cas d'erreur.
          </Paragraph>
        </Section>
      </LegalNoticeContainer>
    </PortailLayout>
  );
};

export default LegalNoticePage;
