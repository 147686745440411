// src/pages/memoires/index.js
import React from 'react';
import Layout from '../../component/Layout/Layout';

export function MemoiresPage() {
  return (
    <Layout>
      <h1>Memoires Page</h1>
      <p>This is the Memoires page. Here you will find information about academic memoires.</p>
    </Layout>
  );
}

export default MemoiresPage;
