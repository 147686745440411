import React, { useState, useEffect, useCallback } from 'react';
import { Flex, HoverCard, Button, Select, Text, Grid } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import CodePenalList from './textComponent';
import { TableOfContentsFloating } from './TabofContent';
import { fonts } from '../themes';

export const CodeContentPage = (props) => {
  const { docdata, texttype } = props;
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [currentId, setCurrentId] = useState("");
  const [textComponent, setTextComponent] = useState(null);
  const [linkok, setLinkok] = useState(null);
  const [links, setLinks] = useState(null);

  const renderCodeItem = (codeItem) => (
    { value: codeItem.doc_id, label: codeItem.title }
  );

  const renderLinks = (codeItem, index) => {
    if (codeItem.type === "Partie") {
      return { label: codeItem.titre, link: `index-${index}`, order: 0 };
    }
    if (codeItem.type === "Livre") {
      return { label: codeItem.titre, link: `index-${index}`, order: 1 };
    }
    if (codeItem.type === "Titre") {
      return { label: codeItem.titre, link: `index-${index}`, order: 2 };
    }
    if (codeItem.type === "Chapitre") {
      return { label: codeItem.titre, link: `index-${index}`, order: 3 };
    }
    if (codeItem.type === "Section") {
      return { label: codeItem.titre, link: `index-${index}`, order: 4 };
    }
  };

  const handleTextItOnChange = (props) => {
    setCurrentId(props);
  };

  // Utiliser useCallback pour stabiliser fetchData
  const fetchData = useCallback(async () => {
    try {
      setLinkok(null);
      const docRef = doc(db, texttype, currentId);
      const text = await getDoc(docRef);
      if (text.exists()) {
        const textBrut = text.data();
        const textB = textBrut["text"];
        setTextComponent(<CodePenalList currentData={textB} />);
        const templink = textB.map(renderLinks).filter(link => link !== undefined);
        setLinks(templink);
        setLinkok(true);
      } else {
        console.log("Le document n'existe pas.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du document :", error);
    }
  }, [currentId, texttype]); // Dépendances

  useEffect(() => {
    if (currentId) {
      fetchData();
    }
  }, [currentId, fetchData]); // Ajoutez fetchData dans le tableau des dépendances

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <Select
        label="Choisir un texte"
        placeholder="Sélectionner un texte"
        value={currentId}
        onChange={(_value) => handleTextItOnChange(_value)}
        data={docdata.map(renderCodeItem)}
        size="xs"
        radius="md"
        searchable
        nothingFound="Aucun texte trouvé"
        clearable
        styles={(theme) => ({
          root: {
            width: '520px',
          },
          input: {
            fontSize: theme.fontSizes.sm,
            padding: '8px 12px',
            borderColor: theme.colors.gray[4],
            fontFamily: fonts.body,
          },
          label: {
            fontWeight: 500,
            marginBottom: '5px',
            fontFamily: fonts.heading,
          },
          rightSection: {
            width: 'fit-content',
          },
          item: {
            '&[data-selected]': {
              backgroundColor: theme.colors.blue[6],
              color: theme.white,
            },
          },
        })}
      />

      <hr />

      {!isMobile ? (
        <Grid>
          <Grid.Col span={4}>
            <div
              id="toc"
              className="toc"
              style={{
                maxHeight: '56vh',
                overflowY: 'scroll',
                paddingRight: '10px',
                borderRight: '1px solid #ccc',
              }}
            >
              {linkok ? <TableOfContentsFloating links={links} /> : <div>Table des matières</div>}
            </div>
          </Grid.Col>
          <Grid.Col span={8}>
            <div
              id="content"
              className="content"
              style={{
                maxHeight: '80vh',
                overflowY: 'scroll',
              }}
            >
              {linkok ? [textComponent] : <Text style={{ fontFamily: fonts.body }}>{currentId}</Text>}
            </div>
          </Grid.Col>
        </Grid>
      ) : (
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 'sm', sm: 'lg' }}
          justify={{ sm: 'center' }}
        >
          <HoverCard shadow="md" withArrow position="bottom" arrowSize={10} withinPortal offset={20}>
            <HoverCard.Target>
              <Button variant="light" size="xs">Table des matières</Button>
            </HoverCard.Target>
            <HoverCard.Dropdown style={{ maxHeight: '20rem', overflowY: 'scroll', maxWidth: '80%' }}>
              <div id="toc"> {links ? <TableOfContentsFloating links={links} /> : <div></div>} </div>
            </HoverCard.Dropdown>
          </HoverCard>

          <div id="content" className="content">
            {textComponent ? [textComponent] : <Text style={{ fontFamily: fonts.body }}>{currentId}</Text>}
          </div>
        </Flex>
      )}
    </div>
  );
};
