import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function RechercheAvanceePage() {
  return (
    <PortailLayout>
      <h1>Recherche Avancée</h1>
      <p>This is the Recherche Avancée page, where you can perform detailed and advanced legal searches.</p>
    </PortailLayout>
  );
}

export default RechercheAvanceePage;
