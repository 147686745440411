import React from "react";
import {Login} from "../../component/login/Login"
import {auth,provider,fbprovider,db} from "../../config/firebase-config"
import { signInWithPopup,getAdditionalUserInfo } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import  {RedirectPage} from "../redirect"

export const Auth = ()=>{
    const navigate = useNavigate()
    const  {isAuth} = useGetUserInfo();

    const signInWithGoogle = (e) => {
      e.preventDefault();
      
      signInWithPopup(auth, provider)
        .then((results) => {
          console.log(results);
          const authInfo = {
            userID: results.user.uid,
            name: results.user.displayName,
            profilePhoto: results.user.photoURL,
            isAuth: true
          };
          localStorage.setItem("auth", JSON.stringify(authInfo));
          console.log(authInfo);
          navigate("/Home");
          const isNewUser = getAdditionalUserInfo(results)?.isNewUser;
          console.log(isNewUser);
          if(!isNewUser){
            /** @type {firebase.auth.OAuthCredential} */
            //var credential = results.credential;
        
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = credential.accessToken;
            // The signed-in user info.
            var user = results.user;
            // ...
            const userCollectionRef = collection(db, "users");
            const addUsers = async (results) => {
              await addDoc(userCollectionRef, {
                created:  serverTimestamp(), 
                msgids:{},
                uid: user.uid,
                online: true, 
                userinfo: {
                name: user.displayName,
                cover: user.photoURL,
                age: '', 
                phone: user.phoneNumber, 
                city:'',
                country: '',
                website: 'https://',
                job: '',
                email: user.email
                },
                customization: {
                color: '#10325c',
                themecolor: '#0f6ce6',
                darkmode: false,
                widemode: false
                }
              });
            };
            addUsers();

        }
        })
        .catch((error) => {
          console.error("Erreur de connexion avec Google : ", error);
        });
    };
    const signInWithFacebook = async () =>{
        signInWithPopup(auth, fbprovider)
        .then((results) => {
          console.log(results);
          const authInfo = {
            userID: results.user.uid,
            name: results.user.displayName,
            profilePhoto: results.user.photoURL,
            isAuth: true
          };
          localStorage.setItem("auth", JSON.stringify(authInfo));
          console.log(authInfo);
          navigate("/Home");
          const isNewUser = getAdditionalUserInfo(results)?.isNewUser;
          console.log(isNewUser);
          if(!isNewUser){
            /** @type {firebase.auth.OAuthCredential} */
            //var credential = results.credential;
        
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = credential.accessToken;
            // The signed-in user info.
            var user = results.user;
            // ...
            const userCollectionRef = collection(db, "users");
            const addUsers = async (results) => {
              await addDoc(userCollectionRef, {
                created:  serverTimestamp(), 
                msgids:{},
                uid: user.uid,
                online: true, 
                userinfo: {
                name: user.displayName,
                cover: user.photoURL,
                age: '', 
                phone: user.phoneNumber, 
                city:'',
                country: '',
                website: 'https://',
                job: '',
                email: user.email
                },
                customization: {
                color: '#10325c',
                themecolor: '#0f6ce6',
                darkmode: false,
                widemode: false
                }
              });
            };
            addUsers();

          }
        })
        .catch((error) => {
          console.error("Erreur de connexion avec Facebook : ", error);
        });
 
    }

    
    return  isAuth===true? <RedirectPage link={"/Home"}/>: <Login signInWithGoogle = {signInWithGoogle} signInWithFacebook = {signInWithFacebook} /> 

    

       /* 
     return   <div className="login-page"> 
            <p>Sign in With </p>
            <button className="login-with-google-btn"
                        onClick={signInWithGoogle} > 
            
            Sign in</button>
            
        </div>

        */
        
        
        

    

};
