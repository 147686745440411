// src/pages/ContactUsPage.js

import React from 'react';
import PortailLayout from '../component/Layout/PortailLayout'; // Adjust the import path if necessary
import ContactUs from '../component/ContactUs';
import FaqWithImage from '../component/FaqWithImage';
export function ContactUsPage() {
  return (
    <PortailLayout>
      <ContactUs />
     <FaqWithImage />
    </PortailLayout>
  );
}

export default ContactUsPage;
