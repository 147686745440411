import cx from 'clsx';
import { useState } from 'react';
import { Box, Text, Group, rem } from '@mantine/core';
import { IconListSearch } from '@tabler/icons-react';
import classes from './newToc.module.css';


export function TableOfContentsFloating(props) {
  const {links} = props
  const [active, setActive] = useState(2);

  const items = links.map((item, index) => (
    <Box
      component="a"
      href={item.link}
      onClick={(event) => {
        event.preventDefault();
        setActive(index);
        console.log(item.link)
        document.getElementById(`${item.link}`).scrollIntoView({
            block: "center",
            behavior: "smooth"
        })

          
      }}
      key={item.label}
      className={cx(classes.link, { [classes.linkActive]: active === index })}
      style={{ paddingLeft: `calc(${item.order} * var(--mantine-spacing-md))`,textAlign: 'left' }}
    >
      {item.label}
    </Box>
  ));

  return (
    <div className={classes.root}>
      <Group mb="md">
        <IconListSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
        <Text>Table des matières</Text>
      </Group >

      <Group align='left'>
      <div className={classes.link}>
        <div
          className={classes.indicator}
          style={{
            transform: `translateY(calc(${active} * var(--link-height) + var(--indicator-offset)))`,
          }}
        />
        {items}
      </div>
      </Group>
    </div>
  );
}