import React, { useState, useEffect } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { Chip, Button, HoverCard, Badge, Input } from '@mantine/core';
import { IconSearch, IconFilter, IconGavel, IconBook, IconList } from '@tabler/icons-react';
import styled from 'styled-components';

// Styles pour le container des boutons (filtres)
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-start;
  padding: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Style de la zone de filtre globale
const FilterArea = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: #f9f9f9;
`;

// Style pour les sous-filtres
const SubFilterArea = styled.div`
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  border-left: 2px solid #ddd;
`;

// Style du dropdown
const Dropdown = styled.div`
  max-height: 15rem;
  overflow-y: auto;
  padding: 0.5rem;
  border-radius: 8px;
`;


const CustomRefinementList = ({ attribute, label, searchable, limit, onCategorySelect, selectedCategories = [], reset, icon }) => {
  const { items, refine, searchForItems } = useRefinementList({ attribute, limit });
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    const sumOfResults = items.reduce((sum, item) => sum + item.count, 0);
    setTotalResults(sumOfResults);
  }, [items]);

  // Gère la sélection et la désélection de catégories multiples
  const handleRefine = (value) => {
    const isSelected = selectedCategories.includes(value);
    refine(value);
    if (attribute === 'category_text') {
      if (isSelected) {
        onCategorySelect(selectedCategories.filter(cat => cat !== value)); // Enlève la catégorie sélectionnée
      } else {
        onCategorySelect([...selectedCategories, value]); // Ajoute la nouvelle catégorie
      }
    }
  };

  // Réinitialise les filtres lorsque reset est déclenché
  useEffect(() => {
    if (reset) {
      items.forEach(item => {
        if (item.isRefined) {
          refine(item.value); // Désélectionne les raffinements
        }
      });
    }
  }, [reset, items, refine]);

  return (
    <HoverCard shadow="md" withArrow position="bottom" arrowSize={10} withinPortal offset={10}>
      <HoverCard.Target>
        <Button
          variant="outline"
          size="xs"
          style={{ margin: '4px', padding: '0.25rem 0.5rem', backgroundColor: '#f5f5f5', color: '#333' }}
          radius="md"
        >
          {icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
          {label} | <Badge color="gray" variant="light" radius="sm">{totalResults}</Badge>
        </Button>
      </HoverCard.Target>

      <HoverCard.Dropdown as={Dropdown}>
        {searchable && (
          <div style={{ marginBottom: '0.5rem' }}>
            <Input
              type="search"
              icon={<IconSearch size={16} />}
              placeholder={`Rechercher ${label}`}
              autoComplete="off"
              spellCheck={false}
              onChange={(event) => searchForItems(event.currentTarget.value)}
              styles={{ input: { borderColor: '#ccc', borderRadius: '6px' }, rightSection: { padding: '0.25rem' } }}
            />
          </div>
        )}
        <hr style={{ margin: '0.5rem 0', borderTop: '1px solid #eee' }} />
        {items.map((item) => (
          <div key={item.label} style={{ marginBottom: '0.25rem' }}>
            <Chip
              size="xs"
              variant={item.isRefined ? 'filled' : 'outline'}
              color={item.isRefined ? 'blue' : 'gray'}
              checked={item.isRefined}
              onChange={() => handleRefine(item.value)}
              radius="md"
              styles={{ root: { padding: '0.3rem', fontSize: '0.8rem', borderRadius: '12px' } }}
            >
              {item.label}
              <Badge
                color="gray"
                variant="filled"
                radius="sm"
                style={{ marginLeft: '0.5rem', fontSize: '0.7rem', backgroundColor: '#3D920C', color: '#fff' }}
              >
                {item.count}
              </Badge>
            </Chip>
          </div>
        ))}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

// Filtres pour les différentes catégories
const FilterLoi = ({ onCategorySelect, selectedCategories, reset }) => (
  <CustomRefinementList 
    attribute="category_text" 
    label="Catégorie" 
    onCategorySelect={onCategorySelect} 
    selectedCategories={selectedCategories} 
    reset={reset}
    icon={<IconFilter size={16} />} 
  />
);

const FilterCode = ({ reset }) => (
  <CustomRefinementList 
    attribute="law_text" 
    label="Lois et Réglements" 
    searchable={true} 
    limit={50} 
    reset={reset}
    icon={<IconBook size={16} />} 
  />
);

const FilterDecisions = ({ reset }) => (
  <CustomRefinementList 
    attribute="juridiction" 
    label="Décisions" 
    searchable={true} 
    limit={40} 
    reset={reset}
    icon={<IconGavel size={16} />} 
  />
);

const FilterKeyword = ({ reset }) => (
  <CustomRefinementList 
    attribute="keyword_decision" 
    label="Mots clés" 
    searchable={true} 
    limit={40} 
    reset={reset}
    icon={<IconList size={16} />} 
  />
);

const FilterDomaine = ({ reset }) => (
  <CustomRefinementList 
    attribute="domaine_decision" 
    label="Domaine de Droit" 
    searchable={true} 
    limit={40} 
    reset={reset}
    icon={<IconList size={16} />} 
  />
);

const FilterTheme = ({ reset }) => (
  <CustomRefinementList 
    attribute="theme_decision" 
    label="Thème" 
    searchable={true} 
    limit={40} 
    reset={reset}
    icon={<IconList size={16} />} 
  />
);

export function HoverFilterComp() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [resetFilters, setResetFilters] = useState(false);

  return (
    <FilterArea>
      <ButtonContainer>
        {/* Filtre principal de la catégorie */}
        <FilterLoi onCategorySelect={setSelectedCategories} selectedCategories={selectedCategories} reset={resetFilters} />

        {/* Affichage des filtres supplémentaires selon la catégorie */}
        {selectedCategories.includes("Lois et Réglements") && (
          <SubFilterArea>
            <FilterCode reset={resetFilters} />
          </SubFilterArea>
        )}

        {selectedCategories.includes("Décisions") && (
          <SubFilterArea>
            <FilterDecisions reset={resetFilters} />
            <FilterKeyword reset={resetFilters} />
            <FilterDomaine reset={resetFilters} />
            <FilterTheme reset={resetFilters} />
          </SubFilterArea>
        )}
      </ButtonContainer>

      {/* Option de réinitialisation en dessous de tous les filtres */}
      {selectedCategories.length > 0 && (
        <div style={{ marginTop: '1rem', textAlign: 'center' }}>
          <Button
            variant="outline"
            size="sm"
            style={{ backgroundColor: '#ff6961', color: '#fff', padding: '0.5rem 1rem' }}
            radius="md"
            onClick={() => {
              setSelectedCategories([]);
              setResetFilters(true); // Réinitialiser tous les filtres
              setTimeout(() => setResetFilters(false), 0); // Réinitialisation complète après le cycle
            }}
          >
            Réinitialiser les filtres
          </Button>
        </div>
      )}
    </FilterArea>
  );
}
