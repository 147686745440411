import React from 'react'
import { Card, Text, Group,Spoiler,Tabs} from '@mantine/core';
import {db} from "../../config/firebase-config"
import {  doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';
import "./articlePage.css"


const TextWithNewLines = ({ text }) => {
  const containerStyle = {
    whiteSpace: 'pre-line',
  };
  return (
    <div style={containerStyle}>
      {text}
    </div>
  );
};


const Anchorpage = (props) =>{

  
  const {beforelink,afterlink} = props
  console.log(beforelink)
  if (beforelink && afterlink){
  return (
    <div>
       <Group justify="center" gap="sm">
      <a href={`/article/${beforelink}`}>
        Article précédent
      </a>
      <a href={`/article/${afterlink}`}>
        Article suivant
      </a>
      </Group>
    </div>
  
  );
  }else if (beforelink){
    return (
      <div>
        <a href={`/article/${beforelink}`}>
          Article précédent
        </a>
      </div>
    );
  }
  else if (afterlink){
    return (
      <div>
        <a href={`/article/${afterlink}`}>
          Article suivant
        </a>
      </div>
    );
  }

};
const ArticleCard =  (props) => {
  const { userId } = props;
  const [articleData, setArticleData] = useState(null); // État pour stocker les données de l'article
  console.log("userId is here",userId)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "law_text", userId);
        const user = await getDoc(docRef);
        if (user.exists()) {
          setArticleData(user.data());
          console.log(articleData)
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      }
    };

    fetchData(); // Appelez la fonction fetchData dans useEffect pour récupérer les données au chargement du composant.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); 

  return (
      <>
      {articleData ? (
        <div className='Card'>
       
        <Anchorpage beforelink={articleData.before} afterlink={articleData.after} />
      
      <br></br>
        <Card shadow="sm" padding="lg" radius="md" withBorder my="2em"  >
        <Group justify="space-between" mt="md" mb="xs">

           <Text fw={500}>{articleData.keyword}</Text>
          </Group>
          <Group  mt="md" mb="xs">
            <Text ta="left" size="sm" c="dimmed"><TextWithNewLines text={articleData.corpus} />   </Text>
          </Group>
          <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Hide">
            <Text size="sm" c="dimmed">
              {/* Utilisez articleData pour afficher les données de l'article */}
            </Text>
          </Spoiler>
          </Card>
        </div>
      ) : (

        <Card shadow="sm" padding="lg" radius="md" withBorder my="2em" style={{"marginLeft":"5%","width": "50%"}}>
          <Group  mt="md" mb="xs" justify="center">
        <Loader color="blue" size="xl" type="dots" />
        </Group>
        </Card>
      )}
      </>
    
  );
}








const ArticleContentPage = (props) => {
  console.log(props)
  let orient =null;
  if (window.innerWidth <= 1000){
    orient= "horizontal"
  }else {
    orient = "vertical"
  }
  return (

        <div>

    
      <Tabs defaultValue="article" orientation={orient} >
      <Tabs.List >
       <Tabs.Tab value="article">Article</Tabs.Tab>
        <Tabs.Tab value="decision">Décisions</Tabs.Tab>
        <Tabs.Tab value="comment">Commentaires</Tabs.Tab>
        <Tabs.Tab value="law">Articles cités ou en lien</Tabs.Tab>

      </Tabs.List>
      <Tabs.Panel value="article"> <ArticleCard {...props}></ArticleCard></Tabs.Panel>
      <Tabs.Panel value="decision">Contenu Décisions</Tabs.Panel>
      <Tabs.Panel value="comment">Contenu Commentaires</Tabs.Panel>
      <Tabs.Panel value="law">Contenu Articles cités</Tabs.Panel>

    </Tabs>
    </div>
    

  );
}

export default  ArticleContentPage;