import React from 'react'
import { Navigate } from "react-router-dom";
export const RedirectPage = (props) => {
      const {link} =  props
        
     return   (
      
      link? <Navigate to={link} />  : <Navigate to="/" />
          );
          
    
}
