import React, { useEffect, useState } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import styled from 'styled-components';
import { ArticleHit } from '../articleHit';

const HitsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 4px;
  padding: 1em 0;
`;

const LoadMoreButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;

const ScrollTopButton = styled.button`
  position: fixed;
  bottom: 60px;
  right: 40px;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'inline' : 'none')};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

function CustomInfiniteHits(props) {
  const { hits: defaultHits, isLastPage: defaultIsLastPage, showMore: defaultShowMore } = useInfiniteHits({ hitsPerPage: 20 });
  // Si `props.hits` est défini, on utilise `props.hits` sinon on prend les valeurs par défaut de `useInfiniteHits`
  const hits = props.hits || defaultHits;
  const isLastPage = props.hits === undefined ? null : defaultIsLastPage;
  const showMore = props.hits === undefined ? null : defaultShowMore;

  const [showScrollTop, setShowScrollTop] = useState(false);

  const handleScroll = () => {
    setShowScrollTop(window.scrollY > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <HitsGridContainer>
        {hits.map((hit) => (
          <ArticleHit key={hit.id_collection || hit.id} hit={hit} />
        ))}
      </HitsGridContainer>
      
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {!isLastPage && (
          <LoadMoreButton onClick={showMore}>Charger Plus</LoadMoreButton>
        )}
      </div>

      <ScrollTopButton onClick={scrollToTop} visible={showScrollTop}>
        ↑
      </ScrollTopButton>
    </>
  );
}

export default CustomInfiniteHits;
