import React from 'react';
import styled from 'styled-components';
import { IconGauge, IconCookie, IconUser, IconMessage2, IconLock } from '@tabler/icons-react';
import { Container } from '@mantine/core';
import { colors, fonts } from './themes'; // Import colors and fonts from the theme

// Define rem function
const rem = (px) => `${px / 16}rem`;

// Mock data with French content related to legal services
export const MOCKDATA = [
  {
    icon: IconGauge,
    title: 'Performance Extrême',
    description:
      'Notre plateforme offre des performances inégalées pour traiter vos recherches juridiques rapidement et efficacement.',
  },
  {
    icon: IconUser,
    title: 'Respect de la vie privée',
    description:
      "Nous garantissons la confidentialité de vos données et veillons à ce que vos recherches restent totalement privées.",
  },
  {
    icon: IconCookie,
    title: 'Protection complète des données',
    description:
      "Vos informations sont gérées en interne, sans intervention de tiers, assurant ainsi la sécurité et l'intégrité de vos données personnelles.",
  },
  {
    icon: IconLock,
    title: 'Sécurité par défaut',
    description:
      "La sécurité de vos données est notre priorité. Notre plateforme est conçue pour protéger vos informations juridiques sensibles.",
  },
  {
    icon: IconMessage2,
    title: 'Support 24/7',
    description:
      "Notre équipe est disponible 24/7 pour vous assister dans vos recherches juridiques et répondre à toutes vos questions.",
  },
];

// Styled components
const FullWidthWrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
`;

const StyledContainer = styled(Container)`
  padding-top: ${rem(64)};
  padding-bottom: ${rem(64)};

  @media (max-width: 768px) {
    padding-top: ${rem(32)};
    padding-bottom: ${rem(32)};
  }
`;

const StyledTitle = styled.h1`
  font-family: ${fonts.heading};
  font-weight: 900;
  margin-bottom: ${rem(16)};
  text-align: center;
  color: ${colors.text};
  font-size: ${rem(32)};

  @media (max-width: 768px) {
    font-size: ${rem(24)};
    text-align: left;
  }
`;

const StyledDescription = styled.p`
  text-align: center;
  margin-bottom: ${rem(40)};
  font-family: ${fonts.body};
  color: ${colors.text};

  @media (max-width: 768px) {
    text-align: left;
    margin-bottom: ${rem(24)};
  }
`;

const FeaturesGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureWrapper = styled.div`
  flex: 1 1 30%;
  max-width: 300px;
  margin-bottom: 2rem;
  padding: ${rem(16)};
  border: 2px solid #f0f0f0;
  border-radius: ${rem(8)};
  transition: transform 0.3s ease;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: left;
    padding: ${rem(12)};
  }
`;

const StyledThemeIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 50%;
  background-color: #f0f4f8;
  margin-bottom: ${rem(8)};
`;

const StyledText = styled.p`
  margin-top: ${rem(8)};
  margin-bottom: ${rem(7)};
  font-family: ${fonts.body};
  font-size: ${rem(16)};
  color: ${colors.text};

  @media (max-width: 768px) {
    font-size: ${rem(14)};
  }
`;

const StyledSmallText = styled.p`
  font-family: ${fonts.body};
  font-size: ${rem(14)};
  color: #6b7280;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: ${rem(12)};
  }
`;

const Separator = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: ${rem(20)} 0;
`;

function Feature({ icon: Icon, title, description }) {
  return (
    <FeatureWrapper>
      <StyledThemeIcon>
        <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
      </StyledThemeIcon>
      <StyledText>{title}</StyledText>
      <StyledSmallText>{description}</StyledSmallText>
    </FeatureWrapper>
  );
}

export function FeaturesGrid() {
  const features = MOCKDATA.map((feature, index) => <Feature {...feature} key={index} />);

  return (
    <FullWidthWrapper>
      <StyledContainer>
        <Separator />
        <StyledTitle>Intégrez notre plateforme juridique avec facilité</StyledTitle>

        <StyledDescription>
          Notre plateforme est conçue pour s'intégrer facilement à vos processus existants, tout en offrant des performances optimales pour vos recherches juridiques.
        </StyledDescription>

        <FeaturesGridWrapper>
          {features}
        </FeaturesGridWrapper>
        <Separator />
      </StyledContainer>
    </FullWidthWrapper>
  );
}

export default FeaturesGrid;
