import React, { useState, useEffect } from 'react';
import { Loader } from '@mantine/core';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';

const OPENAI_API_KEY = "sk-whl7KhEt595GlAm8BV29VorWz7T1Wm_dOhDsSHhfQTT3BlbkFJyCNvtjdkQ5mwbrwlvXfMqRX3nNmm6SEjsAOQZPNmwA"; // Remplacez par votre clé API OpenAI

const SearchSummary = ({ searchInput }) => {
  const [summary, setSummary] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showVoirResultats, setShowVoirResultats] = useState(false);
  const [results,setResults] = useState()

  useEffect(() => {
    const fetchData = async () => {
      if (typeof searchInput === 'string' && searchInput.trim()) {
        setIsLoading(true);
        setSummary('');
        setDisplayedText('');
        setShowVoirResultats(false);

        try {
          const response = await axios.post(
            "https://us-central1-editlaw-68f30.cloudfunctions.net/findNearestDocuments",
            { queryText: searchInput.trim() },
            { headers: { 'Content-Type': 'application/json' } }
          );

          const results = response.data;
          setResults(results)
          const dataContext = results.slice(0,10)
            .map(result => `Texte: ${result.data.law_text}\nTitre: ${result.data.titre}\nContenu: ${result.data.corpus}\n\n`)
            .join('');

          const messages = [
            { role: "system", content: "Vous êtes un assistant juridique spécialisé en droit sénégalais et africains." },
            {
              role: "user",
              content: `
                Voici les documents disponibles extrait pas calcul de similarité compte tenu de ce que l'utilisateur a saisit comme recherche :
                
                ${dataContext}

                En fonction de la recherche : "${searchInput}", veuillez répondre à l'utilisateur comme suit, en français et en fonction des documents à ta disposition:
                - Si l'entrée contient uniquement des mots-clés généraux, faites un résumé en français des documents les plus pertinents.
                - Si l'input semble être une question ou une demande spécifique, répondez-y avec une formulation adaptée en français en utilisant les documents les plus pertinents comme référence.
                - Si les documents ne suffisent pas, proposez des suggestions de recherche pour aider l'utilisateur.
                - S'il sagit de principes ou définitions universelles de droit tu peux répondre si tu es sur. l'orienté vers la réferences pouvant te servir d'illustrations

                Produisez un résumé en français adapté aux résultats et faites des suggestions si nécessaires.
              `
            }
          ];

          const openAIResponse = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-4o-mini",
              messages: messages,
              max_tokens: 4000,
              temperature: 0.2,
            },
            {
              headers: {
                'Authorization': `Bearer ${OPENAI_API_KEY}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const content = openAIResponse.data.choices[0].message.content.trim();
          setSummary(content || 'Aucun résultat pertinent trouvé.');

        } catch (error) {
          if (error.response) {
            console.error('Erreur de récupération Firestore:', error.response.data);
            setSummary(`Erreur ${error.response.status}: ${error.response.data}`);
          } else {
            console.error('Erreur de récupération Firestore:', error.message);
            setSummary('Erreur lors de la récupération des données. Veuillez réessayer.');
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        console.warn('searchInput est vide ou invalide.');
        setSummary('Aucune recherche effectuée pour le moment.');
      }
    };

    fetchData();
  }, [searchInput]);

  useEffect(() => {
    if (!summary) return;
    let index = -1;
    const interval = setInterval(() => {
      if (index < summary.length) {
        setDisplayedText((prev) => prev + summary[index]);
      }
      index++;
      if (index >= summary.length) {
        setDisplayedText(summary);
        clearInterval(interval);
        setShowVoirResultats(true);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [summary]);

  const handleVoirResultats = () => {
    // Utiliser `searchInput` comme clé pour stocker les données dans `localStorage`
    localStorage.setItem(`search_summary_${searchInput}`, JSON.stringify({ summary, results}));
  
    // Ouvrir un nouvel onglet avec `searchInput` dans l'URL
    window.open(`/searchai?query=${encodeURIComponent(searchInput)}`, '_blank');
  };
  

  return (
    <div style={containerStyle}>
      <h2 style={headerStyle}>Résumé de la recherche</h2>
      {isLoading ? (
        <Loader color="blue" size="xl" style={{ margin: '20px auto' }} />
      ) : (
        <>
          <ReactMarkdown style={markdownStyle}>
            {displayedText}
          </ReactMarkdown>
          {showVoirResultats && (
            <button 
              onClick={handleVoirResultats} 
              style={voirResultatsButtonStyle}
            >
              Voir les résultats
            </button>
          )}
        </>
      )}
    </div>
  );
};

// Styles
const containerStyle = {
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ddd',
  width: '100%',
  margin: '0 auto',
  textAlign: 'left',
};

const headerStyle = {
  fontSize: '1.5em',
  color: '#333',
  textAlign: 'center',
};

const markdownStyle = {
  fontSize: '1.1em',
  lineHeight: '1.6',
  color: '#555',
  textAlign: 'justify',
};

const voirResultatsButtonStyle = {
  marginTop: '20px',
  color: '#007bff',
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  display: 'block',
  textAlign: 'center',
};

export default SearchSummary;
