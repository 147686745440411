import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Text, Group, Modal, Loader } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons-react';
import styled from 'styled-components';
import { fonts } from '../themes';

const ScrollTopButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const HTMLContent = ({ html }) => (
  <div
    style={{
      whiteSpace: 'pre-line',
      fontFamily: fonts.body,
    }}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

const CodePenalList = (props) => {
  const { currentData: textData } = props;
  const [lawText, setLawText] = useState(null);
  const [opened, setOpened] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const textContainerRef = useRef(null);

  const handleTitleClick = (article) => {
    setSelectedTitle(article.titre);
    setOpened(true);
  };

  const handleScrollToTop = () => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const renderElement = useCallback((element, index) => {
    switch (element.type) {
      case 'Preambule':
        return (
          <div key={element.titre} id={`index-${index}`}>
            <h3 style={{ fontFamily: fonts.body }}>{element.titre}</h3>
            <Text ta={'left'} size="sm" c="dimmed">
              <HTMLContent html={element.corpus} />
            </Text>
            <br />
          </div>
        );
      case 'Livre':
        return (
          <div key={element.titre} id={`index-${index}`}>
            <h3 style={{ fontFamily: fonts.body }}>{element.titre}</h3>
          </div>
        );
      case 'Titre':
        return (
          <div key={element.titre} id={`index-${index}`}>
            <h4 style={{ fontFamily: fonts.body }}>{element.titre}</h4>
          </div>
        );
      case 'Chapitre':
        return (
          <div key={element.titre} id={`index-${index}`}>
            <h5 style={{ fontFamily: fonts.body }}>{element.titre}</h5>
          </div>
        );
      case 'Section':
        return (
          <div key={element.titre} id={`index-${index}`}>
            <h6 style={{ fontFamily: fonts.body }}>{element.titre}</h6>
          </div>
        );
      case 'Article':
        return (
          <div key={element.titre}>
            <Text
              ta={'left'}
              size="sm"
              style={{ fontFamily: fonts.body, cursor: 'pointer', color: 'blue' }}
              onClick={() => handleTitleClick(element)}
            >
              {element.titre}
            </Text>
            <Text ta={'left'} size="sm" c="dimmed" style={{ marginTop: '10px' }}>
              <HTMLContent html={element.corpus} />
            </Text>
            <br />
          </div>
        );
      default:
        return null;
    }
  }, []); // Le tableau des dépendances est vide car `renderElement` n'utilise pas de variables dépendantes

  const fetchData = useCallback((props) => {
    const transformDoc = (data) => (
      <div key="law-text">
        {data.map((element, index) => renderElement(element, index))}
      </div>
    );

    try {
      const text = transformDoc(props);
      if (text) {
        setLawText(text);
      } else {
        console.log('Le document n\'existe pas.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du document :', error);
    }
  }, [renderElement]); // Ajout de renderElement comme dépendance

  useEffect(() => {
    fetchData(textData);
  }, [textData, fetchData]);

  return (
    <div style={{ position: 'relative' }}>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={selectedTitle}
        size="lg"
      >
        <Text style={{ fontFamily: fonts.body, color: 'gray' }}>Contenu à venir...</Text>
      </Modal>

      <div
        ref={textContainerRef}
        style={{
          maxHeight: '80vh',
          overflowY: 'scroll',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        {lawText ? (
          lawText
        ) : (
          <Group mt="md" mb="xs" justify="center">
            <Loader color="blue" size="xl" type="dots" />
          </Group>
        )}
      </div>

      <ScrollTopButton onClick={handleScrollToTop}>
        <IconArrowUp size={20} />
      </ScrollTopButton>
    </div>
  );
};

export default CodePenalList;
