import React from 'react';
import styled from 'styled-components';
import { Accordion, Container, Title } from '@mantine/core';
import image from '../assets/faq.svg';
import { colors } from './themes';
const placeholder = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

const FaqWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: ${colors.blue.superDark};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1), 0 12px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  
`;

const StyledContainer = styled(Container)`
  padding: 2rem;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e0e0e0;

  display: flex;
  flex-direction: column;
  
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 12px;
`;

const ContentWrapper = styled.div`
  flex: 1;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 1.5rem;
  color: #2d3748;
  font-family: 'Greycliff CF', sans-serif;
`;

const AccordionItem = styled(Accordion.Item)`
  font-size: 1rem;
  color: #4a5568;
`;

export function FaqWithImage() {
  return (
    <FaqWrapper>
      <StyledContainer>
        <ImageWrapper>
          <StyledImage src={image} alt="Frequently Asked Questions" />
        </ImageWrapper>
        <ContentWrapper>
          <StyledTitle order={2}>Questions fréquentes</StyledTitle>

          <Accordion chevronPosition="right" variant="separated">
            <AccordionItem value="reset-password">
              <Accordion.Control>Comment réinitialiser mon mot de passe ?</Accordion.Control>
              <Accordion.Panel>{placeholder}</Accordion.Panel>
            </AccordionItem>

            <AccordionItem value="multiple-accounts">
              <Accordion.Control>Puis-je créer plusieurs comptes ?</Accordion.Control>
              <Accordion.Panel>{placeholder}</Accordion.Panel>
            </AccordionItem>

            <AccordionItem value="newsletter">
              <Accordion.Control>Comment puis-je m'abonner à la newsletter mensuelle ?</Accordion.Control>
              <Accordion.Panel>{placeholder}</Accordion.Panel>
            </AccordionItem>

            <AccordionItem value="data-security">
              <Accordion.Control>Comment mes données juridiques sont-elles sécurisées ?</Accordion.Control>
              <Accordion.Panel>
                Vos données sont stockées de manière sécurisée avec des protocoles de cryptage de pointe pour garantir leur confidentialité et leur intégrité.
              </Accordion.Panel>
            </AccordionItem>

            <AccordionItem value="subscription-costs">
              <Accordion.Control>Quels sont les coûts d'abonnement ?</Accordion.Control>
              <Accordion.Panel>
                Nos tarifs sont flexibles et adaptés à vos besoins spécifiques, avec des options pour les petites entreprises, les cabinets juridiques, et les grandes entreprises.
              </Accordion.Panel>
            </AccordionItem>
          </Accordion>
        </ContentWrapper>
      </StyledContainer>
    </FaqWrapper>
  );
}

export default FaqWithImage;
