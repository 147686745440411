import React, { useState, useEffect } from 'react';
import MyNavbar from '../../component/navbar/Navbar';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Group, Burger, Loader, Select } from '@mantine/core';
import { NavbarNested } from "../../component/verticalNavbar/NavbarNested";
import { db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { CodeContentPage } from '../../component/lawsContent';

export const LawPage = () => {
  const [docData, setDocData] = useState(null);
  const [selectedLawType, setSelectedLawType] = useState("codes"); // Par défaut "codes"
  const [opened, { toggle }] = useDisclosure();

  const fetchData = async (lawtype) => {
    try {
      const docRef = doc(db, "doclist", "doclist");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const docTemp = docSnap.data();
        setDocData(docTemp[lawtype]);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
    }
  };

  useEffect(() => {
    fetchData(selectedLawType);
  }, [selectedLawType]);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Group h="100%" w="100%">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <MyNavbar />
        </Group>
      </AppShell.Header>
      
      <AppShell.Navbar p="md" style={{ backgroundColor: "#F5F5F5" }}>
        <NavbarNested />
      </AppShell.Navbar>

      <AppShell.Main>
        {/* Selecteur pour choisir le type de loi */}
        <Select
          label="Choisir le type de texte"
          placeholder="Sélectionner"
          data={[
            { value: 'code', label: 'Codes Juridiques' },
            { value: 'actes-uniformes', label: 'Actes Uniformes' }
          ]}
          value={selectedLawType}
          onChange={setSelectedLawType} // Mettre à jour le type de loi sélectionné
          size="xs" // Taille plus petite pour le champ Select
          radius="md" // Bords légèrement arrondis pour un look moderne
          transition="pop-top-left"
          transitionDuration={200}
          transitionTimingFunction="ease"
          searchable // Permettre la recherche
          nothingFound="Aucun texte trouvé"
          clearable // Permet de réinitialiser la sélection
          styles={(theme) => ({
            root: {
              width: '520px', // Largeur de 520px comme demandé
            },
            input: {
              fontSize: theme.fontSizes.sm, // Texte plus petit
              padding: '8px 12px', // Espacement réduit
              borderColor: theme.colors.gray[4],
            },
            dropdown: {
              fontSize: theme.fontSizes.sm,
            },
            label: {
              fontWeight: 500, // Mettre le label en gras
              marginBottom: '5px', // Petit espacement pour le label
            },
            rightSection: {
              width: 'fit-content', // Ajuste la largeur de la section contenant la croix de réinitialisation
            },
            item: {
              '&[data-selected]': {
                backgroundColor: theme.colors.blue[6], // Couleur de fond lors de la sélection
                color: theme.white, // Texte en blanc lors de la sélection
              },
            },
          })}
        />

        {/* Affichage des données de la loi sélectionnée */}
        <div>
          {docData ? (
            <CodeContentPage docdata={docData} texttype={selectedLawType} />
          ) : (
            <Group mt="md" mb="xs" justify="center">
              <Loader color="blue" size="xl" type="dots" />
            </Group>
          )}
        </div>
      </AppShell.Main>
    </AppShell>
  );
};
