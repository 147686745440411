import React, { useEffect, useState } from "react"
import { Button,Input } from '@mantine/core';
import {createUserWithEmailAndPassword,signInWithEmailAndPassword} from 'firebase/auth'
import { auth } from "../../config/firebase-config";
import { useNavigate,Navigate } from "react-router-dom";
import {useGetUserInfo} from "../../hooks/useGetUserInfo"

export function Login(props) {
  let [authMode, setAuthMode] = useState("signin")
  
  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }
  const [registerEmail, setRegisterEmail ] = useState("")
  // eslint-disable-next-line
  const [registerName, setRegisterName ] = useState("")  
  const [registerpwd, setRegisterpwd ] = useState("")
  const [loginEmail, setLoginEmail ] = useState("")
  const [loginpwd, setLoginpwd ] = useState("")
  const [msgError,setMsgError] = useState("")
  const [msgLoginError,setMsgLoginError] = useState("")
  const navigate = useNavigate()
  const isAuth = useGetUserInfo()
  
  useEffect(() => {

      console.log(isAuth)
      if (isAuth===true) {
        return  <Navigate to="/Home"/>
      }
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]); 
  const handleSignUp = async (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth,registerEmail, registerpwd)
    .then((user) =>{
      console.log("in")
      console.log(user.user)
      const authInfo = {
        userID: user.user.uid,
        name: null ,
        profilePhoto: null,
        isAuth: true,
        emailVerified:user.user.emailVerified
      };
      
      localStorage.setItem("auth", JSON.stringify(authInfo));
      navigate("/Home")
    })
    .catch((error) => {
        
        switch (error.code) {
          case "auth/email-already-in-use":
            console.log('already subscribe')
            setMsgError("Vous êtes déja inscrits");
            break
          case "auth/invalid-email":
            setMsgError("");
            break;
          case "auth/weak-password":
            setMsgError("Mot de passe trop faible");
            break;
          default:
            setMsgError(error.message);

        }
        setTimeout(() => {
          setMsgError("")
        }, 3000);
      })
      ;
  };


  const handleSignIn = async (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth,loginEmail, loginpwd)
    .then(( user) =>{
      console.log("in")
      console.log(user.user)
      const authInfo = {
        userID: user.user.uid,
        name: null ,
        profilePhoto: null,
        isAuth: true,
        emailVerified:user.user.emailVerified
      };
      localStorage.setItem("auth", JSON.stringify(authInfo));

      navigate("/Home")


    })
    .catch((error) => {
        console.log(error.code)
        switch (error.code) {

          case "auth/invalid-email":
            setMsgLoginError("email invalid")
            break
          case "auth/user/disabled":
            setMsgLoginError('compte désactivé')
          break
          case "auth/user-not-found":
            setMsgLoginError('Email does not exist')
          break
          case "auth/wrong-password":
            setMsgLoginError('Incorrect Password')
          break
          default:
            setMsgLoginError(error.message);

        }
        setTimeout(() => {
          setMsgLoginError("")
        }, 3000);
      })
      ;
  };

  if (authMode === "signin") {
    return (
      <div className="Auth-form-container">
        <form className="Auth-form" id="form-sign-in" >
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">S'identifier</h3>
            <p className="alert" > {msgLoginError}</p>
            <div className="form-group mt-3">
              <label>Adresse mail</label>
              <Input
                
                type="email"
                placeholder="Email"
                onChange={(event) => {setLoginEmail(event.target.value)}}
              />
            </div>
            <div className="form-group mt-3">
              <label>Mot de passe</label>
              <Input
                type="password"
                placeholder="Mot de passe"
                onChange={(event) => {setLoginpwd(event.target.value)}}
              />
            
            </div>
            <div className="d-grid gap-2 mt-3">
              <Button type="submit" className="btn btn-primary"
                     onClick={handleSignIn} >
                Soumettre
              </Button>
              <div className="text-left" style={{"font-size":".7rem"}}>
                    Pas encore de compte?{" "}
                    <span className="link-primary" onClick={changeAuthMode}>
                      S'inscrire
                    </span>
              </div>
          <br></br>

          <div className="flexflow">
                <button className='login-btn gog-btn' onClick={props.signInWithGoogle} >  <i className="fab fa-google"></i>  </button>
                <button className='login-btn fb-btn'  onClick={props.signInWithFacebook}><i className="fab fa-facebook"></i></button>
                
              </div>

            </div>
            <br></br>
            <hr></hr>
            <p className="text-center mt-2" style={{"font-size":".7rem"}}>
              <a href="/passwordforgotten"
              >Mot de passe oublié?</a>
            </p>
          </div>

        </form>
      </div>
    )
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">S'inscrire</h3>
          <p className="alert" > {msgError}</p>

          <div className="form-group mt-3">
            <input
              type="name"
              className="form-control mt-1"
              placeholder="Nom et Prénom"
              onChange={(event) => {setRegisterName(event.target.value)}}
            />
          </div>
          <div className="form-group mt-3">
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Email"
              onChange={(event) => {setRegisterEmail(event.target.value)}}

            />
          </div>
          <div className="form-group mt-3">
            <label>Mot de passe</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Mot de passe"
              onChange={(event) => {setRegisterpwd(event.target.value)}}

            />
          </div>
          <div className="d-grid gap-2 mt-3">
          <Button type="submit" className="btn btn-primary"
                     onClick={handleSignUp} >
                Soumettre
              </Button>
          </div>
          <div className="text-center"  style={{"font-size":".7rem"}}>
            Déjà inscrit?{" "}
            <span className="link-primary" onClick={changeAuthMode}>
            S'identifier
            </span>
          </div>
          <br></br>

          <div className="flexflow">
                <button className='login-btn gog-btn' onClick={props.signInWithGoogle} >  <i className="fab fa-google"></i>  </button>
                <button className='login-btn fb-btn'  onClick={props.signInWithFacebook}><i className="fab fa-facebook"></i></button>
                
              </div>
          <hr></hr>
          <p className="text-center mt-2">
             <a href="/passwordforgottens"
                 >Mot de passe oublié ?</a>
          </p>
        </div>
      </form>
    </div>
  )
}