// src/components/ContactUs.js

import React from 'react';
import styled from 'styled-components';
import { TextInput, Textarea, Button, Container, Group } from '@mantine/core';
import { colors, fonts } from './themes';

const ContactUsWrapper = styled.div`
  padding: 4rem 1rem;
  background-color: ${colors.white};
  text-align: center;
`;

const Title = styled.h2`
  font-family: ${fonts.heading};
  color: ${colors.text};
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-family: ${fonts.body};
  color: ${colors.text};
  font-size: 1.25rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const FormContainer = styled(Container)`
  max-width: 600px;
  margin: 0 auto;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 1.5rem;
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
  background-color: ${colors.blue.dark};
  &:hover {
    background-color: ${colors.blue.superDark};
  }
`;

export function ContactUs() {
  return (
    <ContactUsWrapper>
      <Title>Contactez-nous</Title>
      <Subtitle>Nous sommes là pour répondre à toutes vos questions et vous aider.</Subtitle>
      <FormContainer>
        <form>
          <StyledTextInput
            label="Nom complet"
            placeholder="Votre nom complet"
            required
          />
          <StyledTextInput
            label="Email"
            placeholder="Votre adresse email"
            type="email"
            required
          />
          <StyledTextarea
            label="Message"
            placeholder="Votre message"
            required
          />
          <Group position="center">
            <StyledButton type="submit">Envoyer</StyledButton>
          </Group>
        </form>
      </FormContainer>
    </ContactUsWrapper>
  );
}

export default ContactUs;
