import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth } from "./pages/auth/index";
import { Search } from "./pages/search/index";
import { Searchai } from "./pages/searchai/index";
import { Home } from "./pages/home/index";
import { ArticlePage } from "./pages/article";
import { DecisionPage } from "./pages/decision";
import { LawPage } from "./pages/laws";
import { DecisionsPage } from "./pages/decisions";
import { ExternalApp } from "./pages/chatbot";
import { useGetUserInfo } from "./hooks/useGetUserInfo";
import { RedirectPage } from "./pages/redirect";
import { ConventionsPage } from "./pages/conventions/index";
import { DoctrinesPage } from "./pages/doctrines/index";
import { ThesesPage } from "./pages/theses/index";
import { MemoiresPage } from "./pages/memoires/index";
import { PortailPage } from "./pages/portail/index"; 
import { AssistantJuridiquePage } from "./pages/espace/assistantJuridique/index";
import { RechercheAvanceePage } from "./pages/espace/recherche/index";
import { JurisprudencePage } from "./pages/espace/jurisprudence/index";
import { Doctrines } from "./pages/espace/doctrines/index";
import { AlertesJuridiquesPage } from "./pages/espace/alertesJuridiques/index";
import { BaseDeDonneesPage } from "./pages/espace/baseDeDonnees/index";
import { ActualitesPage } from "./pages/espace/actualites/index";
import { TarifsPage } from "./pages/TarifsPages";
import ContactUsPage from './pages/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage';
import TermsPage from './pages/TermsPage';
import LegalNoticePage from './pages/LegalNoticePage';
import { InstantSearch } from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";

const {searchClient} = instantMeiliSearch(
  'https://meilisearch.legi.sn/', 
  'MobileDevAppTemp1104'
);

function App() {
  const { isAuth } = useGetUserInfo();
  console.log("auth :", isAuth);

  return (
    <div className="App">
      <Router>
        <InstantSearch indexName="law_text" searchClient={searchClient}>
          <Routes>
            <Route path="/" element={<PortailPage />} />
            <Route path="/portail" element={<PortailPage />} />
            <Route path="/Login" exact element={<Auth />} />
            <Route path="Home" element={isAuth ? <Home /> : <RedirectPage />} />
            <Route path="search" element={isAuth ? <Search /> : <RedirectPage />} />
            <Route path="searchai" element={isAuth ? <Searchai /> : <RedirectPage />} />
            <Route path="chatbot" element={isAuth ? <ExternalApp /> : <RedirectPage />} />
            <Route path="/article/:userId" element={isAuth ? <ArticlePage /> : <RedirectPage />} />
            <Route path="/decision/:userId" element={isAuth ? <DecisionPage /> : <RedirectPage />} />
            <Route path="/law" element={isAuth ? <LawPage /> : <RedirectPage />} />
            <Route path="/decisions/:decisionstype" element={isAuth ? <DecisionsPage /> : <RedirectPage />} />
            <Route path="/conventions/:conventionType" element={isAuth ? <ConventionsPage /> : <RedirectPage />} />
            <Route path="/doctrines/:doctrineType" element={isAuth ? <DoctrinesPage /> : <RedirectPage />} />
            <Route path="/theses" element={isAuth ? <ThesesPage /> : <RedirectPage />} />
            <Route path="/memoires" element={isAuth ? <MemoiresPage /> : <RedirectPage />} />
            <Route path="/assistant-juridique" element={<AssistantJuridiquePage />} />
            <Route path="/recherche-avancee" element={<RechercheAvanceePage />} />
            <Route path="/jurisprudence" element={<JurisprudencePage />} />
            <Route path="/doctrines" element={<Doctrines />} />
            <Route path="/alertes-juridiques" element={<AlertesJuridiquesPage />} />
            <Route path="/base-de-donnees" element={<BaseDeDonneesPage />} />
            <Route path="/actualites" element={<ActualitesPage />} />
            <Route path="/tarifs" element={<TarifsPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/legal-notice" element={<LegalNoticePage />} />
          </Routes>
        </InstantSearch>
      </Router>
    </div>
  );
}

export default App;
