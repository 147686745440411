import React from 'react';
import TarifComponent from '../component/TarifComponent';
import PortailLayout from '../component/Layout/PortailLayout'; // Assuming you have this layout component

export function TarifsPage() {
  return (
    <PortailLayout>
      <TarifComponent />
    </PortailLayout>
  );
}

export default TarifsPage;
