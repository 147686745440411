import React from 'react';
import styled from 'styled-components';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { colors, fonts } from './themes'; // Import colors and fonts from theme.js

const FullWidthBackground = styled.div`
  background-color: ${colors.background}; /* Use the background color from theme */
  padding: 3rem 1rem;
  margin-top: 3em;
`;

const PresentationWrapper = styled.div`
  text-align: center;
  max-width: 80%; /* Constrain the content width */
  margin: 0 auto;
`;

const Title = styled.h1`
  font-family: ${fonts.heading}; /* Use the heading font from theme */
  font-weight: 700;
  font-size: 2.75rem;
  margin-bottom: 1.5rem;
  color: ${colors.text}; /* Use the text color from theme */
  letter-spacing: 0.5px;
  line-height: 1.2;
`;

const Subtitle = styled.p`
  font-family: ${fonts.body}; /* Use the body font from theme */
  font-size: 1.25rem;
  line-height: 1.6;
  color: #4a5568;
  margin-bottom: 2rem;
  max-width: 600px; /* Narrow the width of the paragraph */
  margin-left: auto;
  margin-right: auto;
`;

export function Presentation() {
  const navigate = useNavigate();

  return (
    <FullWidthBackground>
      <PresentationWrapper>
        <Title>La première solution d'intelligence juridique au Sénégal</Title>
        <Subtitle>
          Notre solution, alliant intelligence artificielle et expertise humaine, vous aide à maîtriser pleinement votre environnement juridique.
          Bénéficiez d'analyses validées par des juristes chevronnés pour augmenter votre productivité, sécuriser vos décisions, et rester à la pointe du droit.
        </Subtitle>
        <Button variant="outline" color={colors.blue.main} onClick={() => navigate('/register')}>
          Testez gratuitement
        </Button>
      </PresentationWrapper>
    </FullWidthBackground>
  );
}

export default Presentation;
