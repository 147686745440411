// src/pages/TermsPage.jsx
import React from 'react';
import styled from 'styled-components';
import PortailLayout from '../component/Layout/PortailLayout';
import { fonts, colors } from '../component/themes';

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1rem;
  background-color: ${colors.white};
  color: ${colors.text};
  font-family: ${fonts.body};
  margin-top: 4rem;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Title = styled.h3`
  font-size: 1.75rem;
  font-weight: bold;
  color: ${colors.lindworm};
  text-align: left;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
`;

const TableOfContents = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-right: 1.5rem;
  width: 200px;
  position: sticky;
  top: 1rem;

  li {
    margin-bottom: 0.5rem;
    cursor: pointer;
    color: ${colors.primary};
    &:hover {
      text-decoration: underline;
      color: ${colors.accent};
    }
  }
`;

const Separator = styled.div`
  border-left: 1px solid ${colors.accent};
  height: auto;
  margin: 0 1rem;
`;

const ScrollableContent = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
  padding-left: 1rem;
`;

const SectionTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${colors.primary};
  margin-top: 2rem;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
`;

const sections = [
  {
    title: 'Signataire',
    content: 'La plateforme est exploitée par Ndongo Benoit Ndiaye, fournissant des services d’accès à l’information juridique pour le droit sénégalais et africain.',
  },
  {
    title: 'Navigation et Compte d\'essai',
    content: 'L\'accès au contenu est libre. Vous pouvez aussi créer un compte d\'essai gratuit pour accéder à des services supplémentaires.',
  },
  {
    title: 'Niveaux de service',
    content: 'Nous garantissons un service stable, avec des mises à jour régulières pour améliorer les fonctionnalités et l\'accès à notre base de données.',
  },
  {
    title: 'Propriété intellectuelle',
    content: 'Tous les contenus présents sur la plateforme, y compris les textes et les logos, sont protégés par des droits de propriété intellectuelle.',
  },
  {
    title: 'Données personnelles',
    content: 'Les données collectées sont traitées conformément à la réglementation sur la protection des données personnelles, avec une sécurité rigoureuse.',
  },
  {
    title: 'Responsabilité',
    content: 'Nous déclinons toute responsabilité quant aux pertes directes ou indirectes résultant de l\'utilisation de notre plateforme.',
  },
  {
    title: 'Comportements prohibés',
    content: 'Tout accès non autorisé ou détournement des ressources de la plateforme est strictement interdit et passible de sanctions.',
  },
  {
    title: 'Autres mentions',
    content: 'Les informations disponibles sont issues de sources officielles et fiables. Nous nous engageons à maintenir l’intégrité de ces données.',
  },
  {
    title: 'Mises à jour',
    content: 'Les présentes CGU peuvent être mises à jour pour refléter les changements légaux ou technologiques. Nous vous informerons de ces changements par tout moyen adéquat.',
  },
  {
    title: 'Règlement des litiges',
    content: 'En cas de litige, nous privilégions une résolution à l’amiable avant toute action judiciaire. Le tribunal compétent sera déterminé conformément à la législation locale.',
  },
  {
    title: 'Entrée en vigueur',
    content: 'Ces conditions générales d\'utilisation entrent en vigueur dès leur publication et restent applicables jusqu\'à leur modification.',
  }
];

const TermsPage = () => {
  const scrollToSection = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <PortailLayout>
      <TermsContainer>
        <TitleContainer>
          <Title>Conditions Générales d'Utilisation</Title>
        </TitleContainer>

        <ContentWrapper>
          <TableOfContents>
            {sections.map((section, index) => (
              <li key={index} onClick={() => scrollToSection(index)}>
                {index + 1}. {section.title}
              </li>
            ))}
          </TableOfContents>

          <Separator />

          <ScrollableContent>
            {sections.map((section, index) => (
              <div key={index} id={`section-${index}`}>
                <SectionTitle>
                  {index + 1}. {section.title}
                </SectionTitle>
                <Paragraph>{section.content}</Paragraph>
              </div>
            ))}
          </ScrollableContent>
        </ContentWrapper>
      </TermsContainer>
    </PortailLayout>
  );
};

export default TermsPage;
