import React from 'react';
import styled from 'styled-components';
import { Container, Button, Text } from '@mantine/core';
import { colors, fonts } from './themes'; // Importing your theme colors and fonts
import { useNavigate } from 'react-router-dom'; // Importing useNavigate

const PricingWrapper = styled.div`
  padding: 4rem 1rem;
  background-color: ${colors.white};
  text-align: center;
`;

const Title = styled.h2`
  font-family: ${fonts.heading};
  color: ${colors.text};
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-family: ${fonts.body};
  color: ${colors.text};
  font-size: 1.25rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PricingContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PricingCard = styled.div`
  flex: 1 1 300px;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: ${colors.white};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content within the card */

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const PlanTitle = styled(Text)`
  font-family: ${fonts.heading};
  color: ${colors.blue.dark};
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const PlanDescription = styled(Text)`
  font-family: ${fonts.body};
  color: ${colors.text};
  font-size: 1rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const ButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledButtonOutline = styled(Button)`
  background-color: ${colors.white}; /* White interior */
  color: ${colors.blue.dark}; /* Text color */
  border: 2px solid ${colors.blue.dark}; /* Border color */
  height: 45px;
  min-width: 120px;
  padding: 0 20px;
  &:hover {
    background-color: ${colors.blue.light}; /* Light blue on hover */
    color: ${colors.white}; /* White text on hover */
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

const StyledButtonFilled = styled(Button)`
  background-color: ${colors.blue.dark};
  color: ${colors.white}; /* White text */
  height: 45px;
  min-width: 120px;
  padding: 0 20px;
  &:hover {
    background-color: ${colors.blue.superDark};
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

export function TarifComponent() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const plans = [
    {
      title: 'Avocats & Cabinets d’avocats',
      description: 'Améliorez vos résultats, gagnez du temps précieux et renforcez votre stratégie juridique avec des outils avancés pour chaque cas.',
    },
    {
      title: 'Directions juridiques & Audits',
      description: 'Optimisez vos processus internes, améliorez la conformité et renforcez les collaborations inter-départementales pour un impact stratégique.',
    },
    {
      title: 'Universitaires & Chercheurs',
      description: 'Accédez aux ressources les plus récentes et complètes pour enrichir vos recherches, vos cours et vos publications académiques.',
    },
  ];

  return (
    <PricingWrapper>
      <Title>Des solutions conçues pour répondre à vos besoins</Title>
      <Subtitle>Accédez à une base juridique complète, quel que soit votre domaine d'expertise.</Subtitle>
      <PricingContainer>
        {plans.map((plan, index) => (
          <PricingCard key={index}>
            <PlanTitle>{plan.title}</PlanTitle>
            <PlanDescription>{plan.description}</PlanDescription>
            <ButtonsGroup>
              <StyledButtonFilled StyledButtonOutline  onClick={handleContactClick}>
                Nous contacter
              </StyledButtonFilled>
              <StyledButtonOutline variant="outline" onClick={handleLoginClick}>
                Essayer gratuitement
              </StyledButtonOutline>
            </ButtonsGroup>
          </PricingCard>
        ))}
      </PricingContainer>
    </PricingWrapper>
  );
}

export default TarifComponent;
