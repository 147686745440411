import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function Doctrines() {
  return (
    <PortailLayout>
      <h1>Doctrines</h1>
      <p>This is the Doctrines page, where you can access doctrinal analysis and scholarly articles.</p>
    </PortailLayout>
  );
}

export default Doctrines;
