import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function AlertesJuridiquesPage() {
  return (
    <PortailLayout>
      <h1>Alertes Juridiques</h1>
      <p>This is the Alertes Juridiques page, where you can manage and view legal alerts.</p>
    </PortailLayout>
  );
}

export default AlertesJuridiquesPage;
