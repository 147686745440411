import { NavLink } from '@mantine/core';
import { IconHome2, IconBooks, IconMessageChatbot, IconGavel, IconFileCertificate, IconBook, IconSearch, IconDoorEnter } from '@tabler/icons-react';
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styled from 'styled-components';

const ScrollableNavbar = styled.div`
  max-height: 100vh; // Allow the navbar to take the full height of the viewport
  overflow-y: auto; // Make it scrollable vertically
  display: flex;
  flex-direction: column;
  position: relative;
`;

const FixedBottomNavLink = styled(NavLink)`
  position: fixed;
  bottom: 5%; // Always 5% from the bottom of the window
  width: calc(100% - 20px); // Make sure it fits within the navbar with some padding
  left: 50%;
  transform: translateX(-50%);
`;

// Style pour le NavLink actif avec des couleurs sobres
const ActiveNavLink = styled(NavLink)`
  color: #333; // Couleur du texte pour le lien actif (gris foncé sobre)
  font-weight: bold; // Mettre en gras le lien actif
  border-left: 3px solid #444; // Bordure fine gris foncé pour marquer le lien actif
  padding-left: 10px; // Espacement pour la bordure gauche
  transition: all 0.3s ease; // Transition fluide pour un effet moderne

  // Effet au survol pour le rendre plus interactif
  &:hover {
    color: #555; // Change légèrement la couleur au survol (gris plus clair)
  }
`;

const InactiveNavLink = styled(NavLink)`
  color: #777; // Couleur plus claire pour les liens inactifs
  transition: all 0.3s ease; // Transition fluide pour les liens inactifs

  &:hover {
    color: #333; // Assombrir légèrement les liens inactifs au survol
  }
`;

export function NavbarNested() {
  const navigate = useNavigate();
  const location = useLocation(); // Hook pour obtenir la route actuelle
  const [isOpenConventions, setIsOpenConventions] = useState(null);
  const [isOpenDoctrines, setIsOpenDoctrines] = useState(null);

  const handleNavLinkClickparent = (index) => {
    if (index === 1 && !isOpenConventions) {
      setIsOpenConventions(true);
      setIsOpenDoctrines(false);
    } else if (index === 2 && !isOpenDoctrines) {
      setIsOpenConventions(false);
      setIsOpenDoctrines(true);
    } else {
      setIsOpenConventions(false);
      setIsOpenDoctrines(false);
    }
  };

  return (
    <ScrollableNavbar>
      {/* Utilisation de ActiveNavLink si la route correspond à celle du lien */}
      {location.pathname === "/home" ? (
        <ActiveNavLink
          label="Accueil"
          leftSection={<IconHome2 size="1.5rem" stroke={1.5} />} 
          href="/home"
        />
      ) : (
        <InactiveNavLink
          label="Accueil"
          leftSection={<IconHome2 size="1.5rem" stroke={1.5} />}
          href="/home"
        />
      )}

      {location.pathname === "/search" ? (
        <ActiveNavLink 
          label="Recherche" 
          leftSection={<IconSearch size="1.5rem" stroke={1.5} />} 
          href="/search"
        />
      ) : (
        <InactiveNavLink 
          label="Recherche" 
          leftSection={<IconSearch size="1.5rem" stroke={1.5} />} 
          href="/search"
        />
      )}

      {location.pathname === "/chatbot" ? (
        <ActiveNavLink 
          label="Assistant juridique" 
          leftSection={<IconMessageChatbot size="1.5rem" stroke={1.5} />} 
          href="/chatbot"
        />
      ) : (
        <InactiveNavLink 
          label="Assistant juridique" 
          leftSection={<IconMessageChatbot size="1.5rem" stroke={1.5} />} 
          href="/chatbot"
        />
      )}

      {/* Retrait des sous-liens de Lois et Règlements */}
      {location.pathname === "/law" ? (
        <ActiveNavLink
          label="Lois et Réglements"
          leftSection={<IconBooks size="1.5rem" stroke={1.5} />}
          onClick={() => navigate("/law")}
        />
      ) : (
        <InactiveNavLink
          label="Lois et Réglements"
          leftSection={<IconBooks size="1.5rem" stroke={1.5} />}
          onClick={() => navigate("/law")}
        />
      )}

      {/* Retrait des sous-liens de Décisions */}
      {location.pathname === "/decisions" ? (
        <ActiveNavLink
          label="Décisions"
          leftSection={<IconGavel size="1.5rem" stroke={1.5} />}
          onClick={() => navigate("/decisions")}
        />
      ) : (
        <InactiveNavLink
          label="Décisions"
          leftSection={<IconGavel size="1.5rem" stroke={1.5} />}
          onClick={() => navigate("/decisions")}
        />
      )}

      {/* Placer "Conventions" et "Doctrines" en bas */}
      <NavLink
        label="Conventions"
        leftSection={<IconFileCertificate size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(1)} 
        opened={isOpenConventions}
      >
        {location.pathname === "/conventions/collectives" ? (
          <ActiveNavLink
            label="Conventions Collectives"
            onClick={() => navigate("/conventions/collectives")}
          />
        ) : (
          <InactiveNavLink
            label="Conventions Collectives"
            onClick={() => navigate("/conventions/collectives")}
          />
        )}

        {location.pathname === "/conventions/fiscales" ? (
          <ActiveNavLink
            label="Conventions Fiscales"
            onClick={() => navigate("/conventions/fiscales")}
          />
        ) : (
          <InactiveNavLink
            label="Conventions Fiscales"
            onClick={() => navigate("/conventions/fiscales")}
          />
        )}
      </NavLink>

      <NavLink
        label="Doctrines"
        leftSection={<IconBook size="1.5rem" stroke={1.5} />}
        childrenOffset={28}
        onChange={() => handleNavLinkClickparent(2)} 
        opened={isOpenDoctrines}
      >
        {location.pathname === "/doctrines/ouvrages" ? (
          <ActiveNavLink
            label="Ouvrages"
            onClick={() => navigate("/doctrines/ouvrages")}
          />
        ) : (
          <InactiveNavLink
            label="Ouvrages"
            onClick={() => navigate("/doctrines/ouvrages")}
          />
        )}

        {location.pathname === "/doctrines/articles" ? (
          <ActiveNavLink
            label="Articles"
            onClick={() => navigate("/doctrines/articles")}
          />
        ) : (
          <InactiveNavLink
            label="Articles"
            onClick={() => navigate("/doctrines/articles")}
          />
        )}

        {location.pathname === "/doctrines/melanges" ? (
          <ActiveNavLink
            label="Mélanges"
            onClick={() => navigate("/doctrines/melanges")}
          />
        ) : (
          <InactiveNavLink
            label="Mélanges"
            onClick={() => navigate("/doctrines/melanges")}
          />
        )}
      </NavLink>

      {/* "Retour au portail" NavLink fixed at the bottom */}
      <FixedBottomNavLink
        label="Retour au portail"
        leftSection={<IconDoorEnter size="1.5rem" stroke={1.5} />}
        onClick={() => navigate('/portail')}
      />
    </ScrollableNavbar>
  );
}
