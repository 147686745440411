// src/pages/conventions/index.js
import React from 'react';
import Layout from '../../component/Layout/Layout';

export function ConventionsPage() {
  return (
    <Layout>
      <h1>Conventions Page</h1>
      <p>This is the Conventions page. Here you will find information about collective and fiscal conventions.</p>
    </Layout>
  );
}

export default ConventionsPage;
