import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function ActualitesPage() {
  return (
    <PortailLayout>
      <h1>Actualités Juridiques</h1>
      <p>This is the Actualités Juridiques page, where you can stay updated with the latest legal news.</p>
    </PortailLayout>
  );
}

export default ActualitesPage;
