import React, { useEffect, useState } from 'react';
import { Card, Text, Group, Loader, Spoiler, Tabs } from '@mantine/core';
import { db } from "../../config/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import ReactMarkdown from 'react-markdown';
import { theme } from '../themes'; // Import du thème
import "./decisionPage.css";

// Composant générique pour afficher du texte
const TextSection = ({ title, content, isHtml = false }) => (
  <Card
    shadow="sm"
    padding="lg"
    radius="md"
    withBorder
    my="2em"
    style={{
      marginLeft: "5%",
      backgroundColor: theme.colors.white, // Couleur de fond blanche
      border: `1px solid ${theme.colors.accent}`, // Bordure accentuée
    }}
  >
    <Group justify="space-between" mt="md" mb="xs">
      <Text fw={700} size="lg" style={{ color: theme.colors.primary }}>
        {title}
      </Text>
    </Group>
    <Group mt="md" mb="xs">
      {isHtml ? (
        <div dangerouslySetInnerHTML={{ __html: content }} style={{ textAlign: 'left' }} />
      ) : (
        <div style={{ textAlign: 'left' }}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      )}
    </Group>
    <Spoiler maxHeight={80} showLabel="Voir plus" hideLabel="Cacher">
      <Text size="sm" color="dimmed">
        {/* Affichez le texte masqué ici si nécessaire */}
      </Text>
    </Spoiler>
  </Card>
);

// Section pour l'analyse (utilisant la clé corpus)
const AnalysisSection = ({ corpus }) => (
  <TextSection title="Analyse de la décision" content={corpus} />
);

// Section pour la décision brute (utilisant la clé decisions_brut avec balises HTML)
const DecisionSection = ({ decisionsBrut }) => (
  <TextSection title="Décision" content={decisionsBrut} isHtml={true} />
);

// Section pour le résumé (utilisant la clé text_summary)
const SummarySection = ({ textSummary }) => (
  <TextSection title="Résumé de la décision" content={textSummary} />
);

// Message pour les rubriques vides
const EmptySectionMessage = ({ title }) => (
  <Card
    shadow="sm"
    padding="lg"
    radius="md"
    withBorder
    my="2em"
    style={{
      marginLeft: "5%",
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.accent}`,
      textAlign: 'center',
    }}
  >
    <Text fw={500} size="lg" style={{ color: theme.colors.primary }}>
      {title}
    </Text>
    <Text color="dimmed">Ce contenu sera disponible bientôt !</Text>
  </Card>
);

// Page principale avec les Tabs
const DecisionContentPage = ({ userId }) => {
  const [decisionData, setDecisionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "decisions", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setDecisionData(docSnap.data());
        } else {
          console.log("Le document n'existe pas.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document :", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  if (loading) {
    return (
      <Group justify="center" mt="md">
        <Loader color="blue" size="xl" />
      </Group>
    );
  }

  if (!decisionData) {
    return <Text color="red">Aucune donnée disponible</Text>;
  }

  return (
    <div style={{ padding: '2em', backgroundColor: theme.colors.cloud, color: theme.colors.text, fontFamily: theme.fonts.body }}>
      <Tabs defaultValue="summary" orientation={window.innerWidth <= 1000 ? "horizontal" : "vertical"}>
        <Tabs.List>
          <Tabs.Tab value="summary">Résumé</Tabs.Tab>
          <Tabs.Tab value="analysis">Analyse</Tabs.Tab>
          <Tabs.Tab value="decision">Décision</Tabs.Tab>
          <Tabs.Tab value="decisionsimilar">Décisions Similaires</Tabs.Tab>
          <Tabs.Tab value="comment">Commentaires</Tabs.Tab>
          <Tabs.Tab value="law">Articles cités</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="summary">
          {decisionData.text_summary ? (
            <SummarySection textSummary={decisionData.text_summary} />
          ) : (
            <EmptySectionMessage title="Résumé" />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="analysis">
          {decisionData.corpus ? (
            <AnalysisSection corpus={decisionData.corpus} />
          ) : (
            <EmptySectionMessage title="Analyse de la décision" />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="decision">
          {decisionData.decisions_brut ? (
            <DecisionSection decisionsBrut={decisionData.decisions_brut} />
          ) : (
            <EmptySectionMessage title="Décision" />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="decisionsimilar">
          <EmptySectionMessage title="Décisions Similaires" />
        </Tabs.Panel>
        <Tabs.Panel value="comment">
          <EmptySectionMessage title="Commentaires" />
        </Tabs.Panel>
        <Tabs.Panel value="law">
          <EmptySectionMessage title="Articles cités" />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default DecisionContentPage;
