
import ArticleHits from "../articleHits";
import "instantsearch.css/themes/satellite.css";
import { HoverFilterComp } from "../filterChoice/filterChoice";


export const TableOfContentsFloating = () =>{

  return (
      <>
        <HoverFilterComp/>
        <hr/>
        <ArticleHits/>
           
      </>
    
  );
}