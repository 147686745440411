import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function AssistantJuridiquePage() {
  return (
    <PortailLayout>
      <h1>Assistant Juridique</h1>
      <p>This is the Assistant Juridique page, where you can access legal assistance tools and resources.</p>
    </PortailLayout>
  );
}

export default AssistantJuridiquePage;
