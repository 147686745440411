import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function JurisprudencePage() {
  return (
    <PortailLayout>
      <h1>Jurisprudence</h1>
      <p>This is the Jurisprudence page, where you can access and analyze case law.</p>
    </PortailLayout>
  );
}

export default JurisprudencePage;
