// src/pages/doctrines/index.js
import React from 'react';
import Layout from '../../component/Layout/Layout';

export function DoctrinesPage() {
  return (
    <Layout>
      <h1>Doctrines Page</h1>
      <p>This is the Doctrines page. Here you will find information about legal doctrines including articles, books, and mélanges.</p>
    </Layout>
  );
}

export default DoctrinesPage;
