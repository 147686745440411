import React from 'react';
import PortailLayout from '../../../component/Layout/PortailLayout';

export function BaseDeDonneesPage() {
  return (
    <PortailLayout>
      <h1>Base de Données Juridiques</h1>
      <p>This is the Base de Données Juridiques page, where you can access a comprehensive legal database.</p>
    </PortailLayout>
  );
}

export default BaseDeDonneesPage;
