// theme.js

export const colors = {
  background: '#FEF9F0',
  text: '#191919',
  blue: {
    light: '#6EC1E4',
    main: '#007BFF', // Example color used in the navbar
    dark: '#0056b3',
    superDark: '#2F4858', // "#235284"
  },
  primary: '#4C4E49',
  secondary: 'gray',
  accent: '#8F8861',
  highlight: '#E8D8CD',
  cloud: '#F2F2F2',
  white: '#fff',
  coffee: '#393831',
  almond: '#BBAEAB',
  lindworm: '#3030B8',
  scrollTop: '#002C58',
};

export const fonts = {
  heading: '"Times New Roman", Times, serif', // Classic serif font suitable for headings in legal contexts
  body: '"Georgia", serif', // Serif font known for readability in body text
  monospace: '"Courier New", Courier, monospace', // Monospace font for code or legal formatting
};

export const theme = {
  colors,
  fonts,
};
